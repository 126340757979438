import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const About = ({ location }) => (
  <Layout location={location}>
    <SEO title="About" />
    <div>
      <h1>About</h1>
      <p>Learn more about stuff.</p>
    </div>
  </Layout>
)

export default About
